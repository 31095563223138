import "./style.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreOutlined";

export function Skill({ skill_title, skills }) {
  
  function sumItemsLength() {
    return skills.reduce((sum, skill) => sum + skill.items.length, 0);
  }

  return (
    <>
      <Accordion
        sx={{
          backgroundColor: "black",
          marginBottom: "2vh",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ borderBottom: "1px solid white" }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "1rem",
              width: "100%",
            }}
          >
            <h2>
              {skill_title} ({sumItemsLength()})
            </h2>
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ color: "white" }}>
          <div className="">
            {skills.map((skill) => (
              <div className="skill-section">
                <h2 className="skill-title">{skill.title}</h2>
                <div className="skills">
                  {skill.items.map((s) => (
                    <div className="Skill">
                      <img src={`./skills/${s.path}.png`} />
                      <p key={s.name}>{s.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
