import "./style.css";
import { SocialMedia } from "../shared";

export function Header({ name, head, resume }) {
  return (
    <>
      <div className="Header">
        {/* <div className="MyPicture">
          <img src="https://avatars.githubusercontent.com/u/65058505?v=4" />
        </div> */}
        <div className="AboutMe">
          <h1>{name}</h1>
          <h3>{head}</h3>
          <div
            className="Resume"
            dangerouslySetInnerHTML={{ __html: resume }}
          ></div>
          <SocialMedia />
        </div>
      </div>
    </>
  );
}
