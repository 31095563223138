import "./style.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreOutlined";
import { Project } from "../Project";

export function ProjectSection({ project_title, projects, btn_demo, btn_code }) {
  return (
    <>
      <Accordion
        sx={{
          backgroundColor: "black",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ borderBottom: "1px solid white" }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "1rem",
              width: "100%",
            }}
          >
            <h2>
              {project_title} ({projects.length})
            </h2>
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ color: "white" }}>
          {projects.map((project) => (
            <Project
              title={project.title}
              description={project.description}
              video={project.video}
              code={project.code}
              btn_code={btn_code}
              btn_demo={btn_demo}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
