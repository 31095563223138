import logo from "./logo.svg";
import "./style.css";
import { Header } from "./components/Header";
import { Main } from "./components/main";
import { Footer } from "./components/Footer";
import LanguageOption from "./components/LanguageOption";
import {useState} from "react"
import { profileEN } from "./mocks/profile"
function App() {
  const [profile, setProfile] = useState(profileEN)
  return (
    <>
      <div className="ContainerGlobal">
        <div className="Container">
        
          {/* <LanguageOption changeState={setProfile}/> */}
          <Header name={profile.name} head={profile.head} resume={profile.resume}/>
          <Main profile={profile}/>
          <Footer footer={profile.footer} name={profile.name}/>
        </div>
      </div>
    </>
  );
}

export default App;
