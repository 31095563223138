import "./style.css";
export function SocialMedia() {
  return (
    <div className="Links">
      <a href="https://github.com/guirlviana" target="_blank">
        <img src="github.png" />
      </a>

      <a href="https://www.linkedin.com/in/gvianadev" target="_blank">
        <img src="linkedin.png" />
      </a>

      {/* <a href="https://t.me/guirlviana" target="_blank">
        <img src="telegram.png" />
      </a> */}
    </div>
  );
}
