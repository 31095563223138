import { Chip } from "@mui/material";
import "./style.css";

export function Project({
  title,
  description,
  video,
  code,
  btn_demo,
  btn_code,
  projectSkills,
}) {
  return (
    <div className="project">
      <p className="projectTitle" key={title}>
        {title}
      </p>
      <p className="projectDescription">{description}</p>
      <div className="chips">
        {projectSkills?.map((skill) => (
          <Chip variant="outlined" color="default" sx={{ color: "white" }} label={skill} size="small" />
        ))}
      </div>
      <div className="projectBtn">
        {video ? (
          <a href={video} target="_blank">
            <button>{btn_demo}</button>
          </a>
        ) : (
          ""
        )}
        {code ? (
          <a href={code} target="_blank">
            <button>{btn_code}</button>
          </a>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
