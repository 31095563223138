import "./style.css";
import { Skill } from "../Skills";
import { ProjectSection } from "../Projects";
import { Project } from "../Project";

export function Main({ profile }) {
  return (
    <>
      <div className="Main">
        <h2 className="services">Take a look at my products :)</h2>
        {profile.projects.map((project) => (
          <Project
            title={project.title}
            description={project.description}
            video={project.video}
            code={project.code}
            btn_code={profile.btn_code}
            btn_demo={profile.btn_demo}
            projectSkills={project.projectSkills}
          />
        ))}
        <Skill skill_title={profile.skill_title} skills={profile.skills} />
        <ProjectSection
          project_title="Archived projects"
          projects={profile.archivedProjects}
          btn_code={profile.btn_code}
          btn_demo={profile.btn_demo}
        />
      </div>
    </>
  );
}
