import "./style.css";

export function Footer({footer, name}) {
  return (
    <>
      <div className="Footer">
        <p>{name}</p>
        <p>{footer} © {new Date().getFullYear()}</p>
      </div>
    </>
  );
}
